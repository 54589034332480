<style scoped lang="scss">
@import './SectionStyle.scss';
</style>

<template>
  <section class="section section-partners">
    <div class="container">
      <div class="flex-wrapper">
        <div class="item items-center" :style="isLogin ? 'opacity: 0.3;' : ''">
          <div class="item-title">As seen on</div>

          <div class="item-list">
            <div class="logo">
              <img
                :src="`${ASSETS_CDN}/images/general/partners-image-1.png`" width='105' height='22'
                alt="Image - Designer Journeys"
              />
            </div>

            <div class="logo">
              <img
                :src="`${ASSETS_CDN}/images/general/partners-image-2.png`" width='33' height='22'
                alt="Image - Designer Journeys"
              />
            </div>

            <div class="logo">
              <img
                :src="`${ASSETS_CDN}/images/general/partners-image-3.png`"  width='76' height='22'
                alt="Image - Designer Journeys"
              />
            </div>
          </div>
        </div>

        <div class="item flex-wrap items-center" v-if="!isLogin">
          <div class="item-title">Partners</div>

          <div class="item-list">
            <div class="logo">
              <img
                :src="`${ASSETS_CDN}/images/general/partners-image-4.png`"  width='41' height='22'
                alt="Image - Designer Journeys"
              />
            </div>

            <div class="logo">
              <img
                :src="`${ASSETS_CDN}/images/general/partners-image-5.png`"  width='51' height='22'
                alt="Image - Designer Journeys"
              />
            </div>

            <div class="logo">
              <img
                :src="`${ASSETS_CDN}/images/general/partners-image-6.png`"  width='54' height='22'
                alt="Image - Designer Journeys"
              />
            </div>

            <client-only>
              <div v-show="windowWidth >= 385" class="logo">
                <img
                  :src="`${ASSETS_CDN}/images/general/partners-image-7.png`" width='44' height='22'
                  alt="Image - Designer Journeys"
                />
            </div>
            </client-only>
          </div>

          <div class="item-list tl-ml-0">
            <client-only>
              <div v-if=" windowWidth && windowWidth < 385" class="logo">
                <img
                  :src="`${ASSETS_CDN}/images/general/partners-image-7.png`" width='44' height='22'
                  alt="Image - Designer Journeys"
                />
              </div>
            </client-only>
            <div class="logo">
              <img
                :src="`${ASSETS_CDN}/images/general/partners-image-8.png`" width='39' height='22'
                alt="Image - Designer Journeys"
              />
            </div>

            <div class="logo">
              <img
                :src="`${ASSETS_CDN}/images/general/partners-image-9.png`" width='75' height='22'
                alt="Image - Designer Journeys"
              />
            </div>
          </div>
        </div>

        <div
          class="item"
          v-if="isLogin"
          :style="isLogin ? 'opacity: 0.3;' : ''"
        >
          <div class="item-title">Certificate and security</div>
          <div class="item-list">
            <div class="logo">
              <img
                :src="`https://s3-cdn.designerjourneys.com/static/images/general/partners-image-9.png`"
                alt="Image - Designer Journeys"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import {assetCDN} from '~/helper/index.js'

export default {
  props: {
    isLogin: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const store = useStore()
    const ASSETS_CDN = assetCDN()

    const windowWidth = computed(
      () => store.state.generalStore.generalData.windowWidth,
    )
    return {
      windowWidth, ASSETS_CDN,
    }
  }
}
</script>
